<template>
  <div id="award-category-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>

        <v-row wrap>
          <v-col align="right" cols="12" md="12" lg="12">
            <router-link to="/jci-award/judges/list">
              <v-btn
                color="#8950FC"
                elevation="30"
                rounded
                medium
                class="font-size-h6 px-4 mr-3 white--text"
              >
                &nbsp; List &nbsp;
              </v-btn>
            </router-link>
            <v-btn
              medium
              @click.prevent="refreshPageData"
              color="#a4c639"
              elevation="30"
              rounded
              class="font-size-h6 px-4 mr-3 white--text"
              >&nbsp; Refresh &nbsp;
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="12">
                <h5><span class="text-danger">* </span>Kindly Note :</h5>
                <ol class="font-size-h6">
                  <li>
                    Please download sample file here.
                    <a :href="SampleFilePath" target="_blank"
                      >Download sample file</a
                    >
                  </li>
                </ol>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Organization Type</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="OrganizationTypeOptions"
                  :rules="OrganizationTypeRules"
                  v-model="OrganizationType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Judge Type</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="JudgeTypeOptions"
                  :rules="JudgeTypeRules"
                  v-model="JudgeType"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Zone</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6 class="mycard-text1 fsize-3">Upload Excel File</h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  placeholder="Upload Excel File"
                  v-model="UploadedFile"
                  :rules="UploadedFileRules"
                  prepend-icon="mdi-file-excel"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="resetUploadFile">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>

            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  elevation="30"
                  rounded
                  medium
                  color="#1db954"
                  class="font-size-h6 px-6 mr-3 white--text"
                  >Submit</v-btn
                >
                <v-btn
                  @click.prevent="resetAll"
                  elevation="30"
                  rounded
                  medium
                  color="red"
                  class="font-size-h6 px-6 mr-3 white--text"
                  >Clear</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0">
          <br />
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h5 class="green--text" v-html="SuccessMessage"></h5>
            </v-col>

            <v-col align="center" cols="12" md="12">
              <h5 class="red--text" v-html="ErrorMessage"></h5>
            </v-col>
          </v-row>
        </v-container>

        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,
      LoadingFlag: false,
      SelectFlag: true,
      DeleteFlag: false,
      ResultFlag: 0,

      SubmitFlag: false,
      valid1: true,

      SuccessMessage: "",
      ErrorMessage: "",

      Message: "",
      MessageColor: "",

      alert: {},

      valid: true,

      RoleId: "",

      SampleFilePath: "",

      UploadedFile: {},
      UploadedFileRules: [(v) => !!v || "Upload Excel File is required"],

      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationType: "",
      OrganizationTypeOptions: [],

      JudgeTypeRules: [(v) => !!v || "Judge Type is required"],
      JudgeType: "",
      JudgeTypeOptions: [
        {
          value: 174,
          text: "Zone Head Table",
        },
        {
          value: 175,
          text: "National Head Table",
        },
      ],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordData: {},

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var server_url = companyConfig.apiURL;
        console.log("server_url = " + server_url);
        var SampleFilePath =
          server_url + "assets/sample/head_table_upload_sample.xlsx";
        console.log("SampleFilePath = " + SampleFilePath);
        this.SampleFilePath = SampleFilePath;

        this.getOrganizationTypeOptions();
        // this.getZoneCodeOptions();
      }
    },
    OrganizationTypeOptions: function () {
      console.log("watch OrganizationTypeOptions");
      this.OrganizationTypeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "award_judges",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    resetUploadFile() {
      console.log("resetUploadFile called");
      this.UploadedFile = {};
      this.ErrorMessage = "";
      this.ErrorMessageTxt = {};
      this.SuccessMessage = "";
      this.MessageColor = "";
      this.Message = "";
    },
    resetAll() {
      console.log("resetAll called");
      this.UploadedFile = {};
      this.ErrorMessage = "";
      this.ErrorMessageTxt = {};
      this.SuccessMessage = "";
      this.MessageColor = "";
      this.Message = "";
      this.OrganizationType = "";
      this.JudgeType = "";
    },

    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");
      var selectbox1_source = "OrganizationType";
      var selectbox1_destination = "OrganizationTypeOptions";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("OrganizationType", this.OrganizationType);
        upload.append("Role", this.JudgeType);
        upload.append("UploadFile", this.UploadedFile);

        this.progessStart = 1;
        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judge/bulk-upload";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var error = "";
        var success = "";
        var error_message = {};
        var records = {};
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.progessStart = 0;

            success = response.data.success;
            error = response.data.error;
            flag = response.data.flag;
            records = response.data.records;
            console.log({ flag });
            console.log({ success });
            console.log({ error });
            console.log({ records });

            thisIns.SuccessMessage = success;
            thisIns.ErrorMessage = error;

            // if (flag) {
            //   thisIns.UploadedFile = {};
            //   thisIns.OrganizationType = "";
            //   thisIns.JudgeType = "";
            // }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss"></style>
